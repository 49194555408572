/* FIXES
--------------------------------------------------------------------------------------------*/


//FORMS

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

[data-s-type="hero"] .ac_hero_container {
    height: 75vh !important;
}

.ac_hero_slide_content_text_desciption {
    p {
        font-size: 40px;
        font-weight: bold;
        text-shadow: 2px 2px #444444;
    }
}
.ac_hero_slide_content_text {
    a  {
        color:$brand-white !important;
    }
}

#opzegging-sectie-titel-3-3 .ac_content_container .ac_content_flex  {
    display:block !important;
}

//HEADERS

[data-s-type="hero-alt"] {
    position: relative;
    background: linear-gradient(
-135deg
,$brand-primary-darker, $brand-primary) !important;
    background-image: linear-gradient(
-135deg
, rgb(143, 32, 31), rgb(222, 34, 30)) !important;
    background-position-x: initial !important;
    background-position-y: initial !important;
    background-size: initial !important;
    background-repeat-x: initial !important;
    background-repeat-y: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: initial !important;

}

.ac_menu-2_mobile_logo_img {
    display:none;
}
.ac_menu-2_logo_img {
    display: block;
}

.ac_menu-2_logo_img {
    width: 350px;
    max-width: 350px;
    margin-bottom: 10px;
    height: auto;
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 350px !important;
    max-width: 350px !important;
    margin-bottom: -20px !important;
    height: auto;
}

.ac_menu-2_bar_top {
    background: none;
    a {
        color:$brand-tertiary !important;
    }
}

.ac_menu-2_bar_top_container {
    /*padding-right: 0px;*/
}

@media screen and (max-width: 1100px){
    [data-s-type="hero"] .ac_hero_container {
        height: 60vh !important;
    }
    
}

.button.v_has-icon-left .svg-container {
    margin-right: 10px;
}

.button.v_has-icon-right .svg-container {
    margin-left: 10px;
}
[data-m-type="menu-2"] a {
    font-size: 11pt !important;
}
.home {
    .ac_menu-2_bar_bottom {
        background: $brand-primary;
    }
    .ac_menu-2_bar_bottom {
        a {
            color:#ffffff !important;
        }
        .is-dropdown-submenu {
            a {
                color:#222222 !important;
            }
        }
    }
}

[data-m-type="menu-2"] a.button {
    font-size: 16pt !important;
    margin-right:12px;
    padding:0.4em;
}

[data-m-type="menu-2"] a:hover {
    
    color:$brand-black;
}

[data-s-type="hero"] .ac_hero_slide_content_inner {
    justify-content: flex-start;
}


.ac_menu-2_cta_link {
    font-size: 20px !important;
}

[data-m-type="menu-2"] {
    a {
        color:$brand-black;
        font-weight: 600;
        font-size: 13px;
    }
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:300px
}

#breadcrumbs {
    display: none;
}

.ac_menu-2_btn-search svg {
    width: 30px;
    height: 30px;
    margin-top: -13px;
}

//Menus
.ac_menu-2_sticky_container {
    margin-top: -40px;
}

.ac_menu-2_bar_top_container {
    justify-content: right;
    a {
        font-weight: 600;
    }
}

.ac_menu-2_bar_bottom_container {
    justify-content: right !important;
    a {
        font-weight: 600;
    }
}

.ac_menu-2_bar_top {
    padding:10px;
    background: none;
}


.ac_hero_slide {
    height:80vh;
}

.ac_hero_alt_slide {
   height:175px;
}
[data-s-type="hero"] .ac_hero_container {
    height:100% !important;
}

.slick-dots {
    bottom:40px !important;
}

.button:hover {
    color:#fff;
}

[data-s-amount="2"] .slick-slide [data-s-amount-item]{
    max-width: initial !important;
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:175px;
    min-height: 175px;
}

[data-s-type="hero-alt"] h1, [data-s-type="hero-alt"] h2, [data-s-type="hero-alt"] h3 {
    padding-top:20px;
}

[data-s-type="hero"] {
    height:80vh;
}
.ac_hero_slide_content_text {
    display: block;
    h2 {
        font-size: 34px !important;
        font-weight: bold;
        text-shadow: 2px 2px #5a5a5a;
        text-align: center;
    }
}


@media screen and (max-width: 1100px){
    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        width: 200px !important;
        height: auto;
        margin-top:10px !important;
    }

    .ac_menu-2_logo_img {
        width: 200px !important;
        height: auto;
        margin-top:10px !important;
    }
}

.ac_menu-2_logo_container + .ac_menu-2_main_content{
    margin-left: auto;
    margin-right: 5px;
}


// MENU MOBILE
@media screen and (max-width: 49.9375em){
    [data-m-type="menu-2"] {
        margin-top: 40px;
    }

    [data-s-type="hero"] {
        height:60vh;
    }
    .ac_hero_slide {
        height:60vh;
    }

    .ac_menu-2_sticky{
        display: block;
    }

    
    .ac_menu-2_logo_img {
       padding:10px;
    }

    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        //width: 150px !important;
        height: auto;
        margin-top:10px !important;
    }


    .ac_menu-2_main_content {
        padding-left:0px !important;
        margin-left:0px !important;
    }

    [data-s-type="hero"] .ac_hero_container {
        height: 50vh !important;
    }
    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}
@media screen and (max-width: 800px){
    .home h2 {
        color: #646463;
        font-weight: 600;
        text-align: center;
    }
}

h2, h3, h4 {
    color: $brand-primary;
    padding-bottom:10px;
}

// GENERAL
.home {
    h2 {
        color:$brand-tertiary;
    }
}

.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-primary;
    &.button {
        color: $brand-white;
        text-decoration: none;
    }
}

.button {
    background: $brand-primary-darker;
}

body {
    color:$brand-black;
}

//NEWS
@media screen and (max-width: 800px){
    [data-s-type="news"] .ac_item .ac_item_content_copy {
        padding: 0;
        margin-left: 90px;
        max-width: 80%;
    }
}

[data-s-type="news"] .ac_item_content_title {
    color: $brand-black;
}


// BRANDS

.ac_brands_item_container {
    border: none !important;
}

.ac_brands_item {
    border: none !important;
}

// SERVICES
[data-s-type="services"] .ac_item_content_copy-below {
    display: block;
    font-family: "Titillium Web", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 150%;
    color: white;
}

[data-s-type="services"] .ac_item_icon svg {
    display:none;
}

[data-s-type="services"] .ac_item_content_title {
    display: none;
}


[data-s-id="home-services"] .ac_container {
    margin: 0px;
    max-width: 1600px;
    margin-left: auto !important;
    margin-right: auto !important;
}

[data-s-id="home-services"] {
    padding-bottom: 0px;
    h2 {
        color:$brand-white;
    }
    .ac_container {
        margin:0px;
    }
}


//FOOTER
[data-f-type="footer-1"] h2 {
    font-size: 160%;
    margin-bottom: 0px;
    opacity: .5;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background: $brand-black;
}

[data-f-type="footer-1"] .ac_footer_primary_container {
    padding: 20px 10px 5px 10px;
}

@media screen and ( max-width: 800px ){

    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4) {
        margin-left: -12px;
    }
}

[data-f-type="footer-1"] .ac_footer_primary {
    background: $brand-tertiary-darker;
    border-top:2px solid $brand-light-gray;

    .ac_footer_primary_column{
        flex: 1 !important;
        max-width: initial !important;
    }

    .alignnone{
        width: 90%;
        float: right;
        margin: -100px 0 0 0;
    }
}


.ac_footer_primary_container {
    padding-top:40px !important;
    padding-bottom:20px !important;
}

//CTA
.ac_cta_column {
    p {
        font-size: 18px;
        font-weight: 600;
    }
    .button.v_transparent_body {
        color: $brand-primary;
        font-weight: 600;
        font-size: 16px;
    }
}


.ac_cta_column {
    h2 {
        color:$brand-tertiary;
    }
    .button {
        margin-bottom:5px !important;
    }
}

[data-s-type="cta"] {
    padding:0 !important;
}

// PROJECTS
@media print, screen and (min-width: 40em) {
    [data-s-type="references"] .ac_item {
        height: 300px;
    }
}

[data-s-type="references"] .ac_item_image-container {
    background: #ffffff;
}

[data-s-type="references"] .ac_item_image {
    opacity: 1;
}

// IMAGES OVERLAY
[data-s-type="image-gallery_full-screen"] .slick-next svg, [data-s-type="image-gallery_full-screen"] .slick-prev svg {
    fill: $brand-primary;
}

li::marker {
    color:$brand-primary;
}

.ac_content_1-1 {
    padding-left: 10px;
    padding-right: 10px;
}

//brands
.ac_brands_item_logo {
    -webkit-filter: none !important;
    filter: none !important;
}

//toptasks
@media print, screen and (min-width: 1100px) {
    [data-s-type="toptask-text"] {
        margin-top: -100px;
        z-index: 10;
        width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding: 40px;
        h1 {
            padding-bottom: 20px !important;
        }
    }
}

@media print, screen and (max-width: 1100px) {
    [data-s-type="toptask-text"] {
        margin-top: -100px;
        z-index: 10;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 40px;
        h1 {
            padding-bottom: 20px !important;
        }
    }
}

.ac_toptasks_item:hover {
    .ac_toptasks_item_icon {
        background: $brand-white;
        transition: all 0.3s ease;
    }
}

.ac_toptasks_item_image-container {
    background: none;
}

.ac_toptasks_item_icon-container {
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}
.ac_toptasks_item_icon svg {
    width: 70px;
    height: 70px;
    transition: all 0.3s ease;
    stroke: #646463;
    fill: transparent;
}
.ac_toptasks_item_icon {
    width: 80px;
    height: 80px;
    background: none;
    padding: 6px;
    border-radius: 100%;
    transition: all 0.3s ease;
}

.ac_content_1-2 {
    iframe {
        width:100%;
    }
}
/*
.ac_toptasks_item_image-container {
    background: #E3E3E3;
}

.ac_toptasks_item_container {
   background: #E3E3E3;
}

.ac_toptasks_item_icon svg {
    color: $brand-primary !important;
    stroke: $brand-primary !important;
    fill:#fff;
}

.ac_toptasks_item_content_title {
   color: $brand-primary !important;
}
*/